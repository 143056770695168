'use strict'
const _ = require('lodash')
const urlUtils = require('./urlUtils')

function getVersionFromUrl(url) {
    const version = url.match(/\d+.\d+.\d+/g)
    return version ? version[0] : '0.0.0'
}

//return apps that are not revoked
function filterRevokedApps(app) {
    const appIsRevoked = _.get(app, 'permissions.revoked')
    return !appIsRevoked
}

function resolve(apps, scriptsLocation) {
    const nativeOverrides = nativeCompoentnOverrides(scriptsLocation)
    const componentsFromSpecMap = _(apps)
        .filter(filterRevokedApps)
        .map(app => buildWidget(app && app.widgets, nativeOverrides))
        .compact()
        .flatten()
        .values()
        .value()

    return componentsFromSpecMap
}

function nativeCompoentnOverrides(scriptsLocation) {
    const clientSpecOverrides = {}
    if (typeof window !== 'undefined') {
        const overrides = urlUtils.getParameterByName('nativeComponentsOverrides')
        if (overrides) {
            try {
                const nativeComponentsOverrides = JSON.parse(overrides)
                if (_.isArray(nativeComponentsOverrides)) {
                    nativeComponentsOverrides.forEach(override => {
                        if (override.componentUrl && override.widgetId && isValidOverrideUrl(override.componentUrl, scriptsLocation)) {
                            clientSpecOverrides[override.widgetId] = override
                        }
                    })
                }
            } catch (error) {
                //TODO: Report Error
            }
        }
    }
    return clientSpecOverrides
}

function isValidOverrideUrl(url, scriptsLocation) {
    return url.indexOf(scriptsLocation) === 0 ||
        url.indexOf('http://localhost') === 0 ||
        url.indexOf('https://localhost') === 0
}

function applyOverrides(widget, override){
    if (override && override.componentUrl){
        widget.componentFields = widget.componentFields || {}
        widget.componentFields.componentUrl = override.componentUrl
    }
}

function buildWidget(widgets = {}, nativeOverrides) {
    const widgetsWeSupport = _(widgets)
        .map(widget => {
            applyOverrides(widget, nativeOverrides[widget.widgetId])
            if (_.get(widget, 'componentFields.componentUrl')) {
                const type = `native.components.${widget.widgetId}.${getVersionFromUrl(widget.componentFields.componentUrl)}`
                return {
                    widgetId: widget.widgetId,
                    url: `${widget.componentFields.componentUrl}.js`,
                    type
                }
            }
            return undefined
        })
        .compact()
        .values()
        .value()

    return _.isEmpty(widgetsWeSupport) ? undefined : widgetsWeSupport
}

module.exports = resolve