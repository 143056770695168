'use strict'

const _ = require('lodash')

function getPageFileNames(publicModel) {
    if (!publicModel) {
        return {}
    }

    const pageList = publicModel.pageList
    return _(pageList.pages)
        .filter(p => _.has(p, 'pageJsonFileName'))
        .reduce((res, p) => _.assign(res, {[p.pageId]: p.pageJsonFileName.replace('.json', '')}),
            {masterPage: pageList.masterPageJsonFileName.replace('.json', '')})
}

module.exports = {
    getPageFileNames
}
